export default class Fill {
  constructor ({ element, controller: { slider, handles } }) {
    this.element = element
    this.handles = handles
    this.slider = slider
    this.#setNeigbours()
    this.render()
  }

  #setNeigbours () {
    const { element, handles } = this
    this.previousSiblingHandle = handles[element.previousElementSibling.dataset.key]
    this.previousSiblingHandle?.fillsToCareAbout?.push(this)
    this.nextSiblingHandle = handles[element.nextElementSibling.dataset.key]
    this.nextSiblingHandle?.fillsToCareAbout?.push(this)
  }

  render () {
    const { element } = this
    element.style.left = `${this.point}px`
    element.style.width = `${this.size}px`
  }

  get startPoint () {
    return this.previousSiblingHandle?.point || 0
  }

  get endPoint () {
    return this.nextSiblingHandle?.point || this.slider.rect.width
  }

  get point () {
    let point = this.startPoint

    if (point > this.endPoint) {
      point = this.endPoint
    }

    return point
  }

  get size () {
    let baseSize = this.endPoint
    if (baseSize < this.startPoint) {
      baseSize = this.startPoint
    }

    return baseSize - this.point
  }
}
