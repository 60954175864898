import ApplicationController from 'controllers/application-controller'
import debounce from 'lodash/debounce'

export default class AttachmentsController extends ApplicationController {
  static values = {
    url: String,
    attributeName: String,
    validations: Object
  }

  static targets = ['dropZone', 'input', 'attachmentTemplate', 'error']
  static classes = ['dragOver', 'dragNearby']
  static defaultClasses = { dragOver: 'drag-over', dragNearby: 'drag-nearby' }

  connect () {
    super.connect()
    this.attributeName ||
      console.error('attributeName value of inputField target needs to be provided')
    this.#hideFileInput()
    this.attachmentControllers = []
    this.droppedFiles = {}
    this.handleDragOverWindow = debounce(this.handleDragOverWindow.bind(this), 100, { maxWait: 100, leading: true })
    this.addEventListener('attachment:connect', e => this.#onAttachmentControllerConnect(e))
    this.addEventListener('attachment:remove', e => this.#handleAttachmentRemove(e))
    this.addEventListener('attachment:complete', () => this.#handleAttachmentComplete())
  }

  #hideFileInput () {
    if (!this.hasInputTarget) return
    this.inputTarget.disabled = true
    this.inputTarget.style.display = 'none'
  }

  handleClick () {
    if (!this.hasInputTarget) return
    this.inputTarget.disabled = false
    this.inputTarget.click()
    this.inputTarget.disabled = true
  }

  handleDragOverWindow () {
    this.dropZoneTarget.classList.add(this.dragNearbyClass)
    this.draggingOverWindow = Date.now()
    globalThis.setTimeout(() => {
      if (Date.now() - this.draggingOverWindow < 100) return

      this.dropZoneTarget.classList.remove(this.dragNearbyClass)
    }, 500)
  }

  handleDragOver (ev) {
    ev.preventDefault() // without this drop goes to window
  }

  handleDragEnter (ev) {
    if (!this.#eventGuard(ev)) return

    this.dropZoneTarget.classList.add(this.dragOverClass)
  }

  handleDragLeave (ev) {
    if (!this.#eventGuard(ev)) return

    this.dropZoneTarget.classList.remove(this.dragOverClass)
  }

  handleDrop (ev) {
    if (!this.#eventGuard(ev)) return
    this.#processFilesToUpload(ev.dataTransfer.files)
  }

  handleInputChange () {
    this.#uploadFiles(this.inputTarget.files)
  }

  #processFilesToUpload (files) {
    this.#uploadFiles(files)
  }

  #eventGuard (ev) {
    const { dataTransfer } = ev
    const result = dataTransfer && dataTransfer.items.length > 0
    result && ev.preventDefault()
    return result
  }

  #uploadFiles (files) {
    Array.from(files).forEach(file => {
      this.#appendAttachmentController({ file, template: this.attachmentTemplateTarget, dropZone: this.dropZoneTarget })
    })
  }

  #appendAttachmentController ({ template, dropZone, file }) {
    const element = template.content.firstElementChild.cloneNode(true)
    const hash = `${file.name}_${file.size}`
    this.droppedFiles[hash] = file
    element.dataset.attachmentHashValue = hash
    dropZone.appendChild(element)
    return element
  }

  #onAttachmentControllerConnect ({ detail: controller }) {
    if (!controller.hasHashValue) { return }
    // next code only for the dropped files

    const file = this.droppedFiles[controller.hashValue]
    if (!file) { console.error(`file "${controller.hashValue}" not found in droppedFiles object`); return }

    this.#registerAttachmentController(controller)
    controller.setValues({
      url: this.url,
      attributeName: this.attributeName,
      validations: this.validationsValue
    })
    controller.uploadFile(file)
  }

  #registerAttachmentController (controller) {
    this.attachmentControllers.push(controller)
  }

  #handleAttachmentComplete () {
    if (!this.allAttachmentsCompleted) return

    this.stimulate('IntentForm::AttachmentsComponent#update', this.element)
  }

  #handleAttachmentRemove ({ detail: attachmentController }) {
    if (!this.allAttachmentsCompleted) {
      globalThis.setTimeout(() => this.#handleAttachmentRemove({ detail: attachmentController }), 100)
      return
    }

    if (!attachmentController.hasIdValue) {
      attachmentController.element.parentNode?.removeChild(attachmentController.element)
      return
    }

    this.stimulate('IntentForm::AttachmentsComponent#remove', attachmentController.element)
  }

  get allAttachmentsCompleted () {
    return (
      this.attachmentControllers.length === 0 ||
        this.attachmentControllers.findIndex(controller => !controller.completed) < 0
    )
  }

  get url () {
    return this.hasInputTarget ? this.inputTarget.dataset.directUploadUrl : this.urlValue
  }

  get attributeName () {
    return this.hasInputTarget ? this.inputTarget.name : this.attributeNameValue
  }

  get form () { return this.element.closest('form') }

  get submitButton () { return this.form?.querySelector('input[type=submit], button[type=submit]') }
}
