import ApplicationController from 'controllers/application-controller'

export default class CookiesAcceptanceComponentController extends ApplicationController {
  static values = { forceShow: Boolean }
  static classes = ['block', 'collapsed', 'animation']
  static defaultClasses = {
    block: 'cookies-acceptance',
    collapsed: 'cookies-acceptance--collapsed',
    animation: 'cookies-acceptance--is-animating'
  }

  connect () {
    super.connect()
    if (this.shouldShowMessage) {
      this.show()
    } else {
      this.remove()
    }
  }

  dismiss () {
    this.cookiesAccepted = true
    this.remove()
  }

  remove () {
    this.withAnimation(() => { this.element.classList.add(this.collapsedClass) })
    setTimeout(() => { this.element.remove() }, 700)
  }

  show () {
    if (!this.element.classList.contains(this.collapsedClass)) return
    this.withAnimation(() => { this.element.classList.remove(this.collapsedClass) })
  }

  withAnimation (block) {
    this.element.classList.add(this.animationClass)
    setTimeout(() => {
      block()
      setTimeout(() => {
        this.element.classList.remove(this.animationClass)
      }, 300)
    }, 300)
  }

  get shouldShowMessage () {
    return process.env.RAILS_ENV !== 'test' && !this.cookiesAccepted
  }

  get cookiesAccepted () {
    return this.storage.getItem('cookiesAccepted') === 'true'
  }

  set cookiesAccepted (value) {
    this.storage.setItem('cookiesAccepted', value ? 'true' : 'false')
  }

  get storage () {
    return window.localStorage
  }
}
