import Rect from 'lib/rect'

export default class Slider {
  constructor ({ element, controller }) {
    this.element = element
    this.controller = controller
    this.minValue = controller.minValue
    this.maxValue = controller.maxValue
    this.updateDimensions()
  }

  updateDimensions () {
    this.rect = new Rect(this.element)
  }

  processDragEnd (_handle) {}

  clientPointToRelative (point) {
    const { rect } = this
    const offsetPoint = point - rect.x

    if (offsetPoint < 0) {
      return 0
    } else if (offsetPoint > rect.width) {
      return rect.width
    }

    return offsetPoint
  }

  validateInput (handle) {
    const value = Number.parseFloat(handle.input.value)

    if (Number.isNaN(value)) {
      handle.input.value = handle.value
      return handle.value
    } else if (value < this.minValue) {
      handle.input.value = this.minValue
      return this.minValue
    } else if (value > this.maxValue) {
      handle.input.value = this.maxValue
      return this.maxValue
    }

    return value
  }

  valueAtHandle ({ point }) {
    const rate = this.rateAtPoint(point)
    const range = this.maxValue - this.minValue
    return Math.round(range * rate + this.minValue)
  }

  pointAtValue (value) {
    return this.pointAtRate((value - this.minValue) / this.maxValue)
  }

  rateAtPoint (point) {
    return this.map(point, this.rect.width)
  }

  pointAtRate (rate) {
    return this.reverseMap(rate, this.rect.width)
  }

  get map () {
    return this.constructor.maps[this.controller.valueMapFunctionName].normal
  }

  get reverseMap () {
    return this.constructor.maps[this.controller.valueMapFunctionName].reverse
  }

  static maps = {
    linear: {
      normal: (val, scale) => val / scale,
      reverse: (val, scale) => val * scale
    },
    cubic: {
      normal: (val, scale) => Math.pow(val / scale, 3),
      reverse: (val, scale) => scale * Math.cbrt(val)
    }
  }
}
