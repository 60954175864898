import ApplicationController from './application-controller'
import { useHover } from 'stimulus-use'
import $ from 'lib/query-selector'

export default class extends ApplicationController {
  static values = {
    on: String,
    target: String,
    class: String
  }

  connect () {
    super.connect()
    this.toggledWith = null
    this.initTarget()
    this.initHover()
  }

  initTarget () {
    if (this.targetValue !== '') {
      this.target = $(this.targetValue)
    } else {
      console.warn('toggler-class-value not provided')
    }
  }

  // TODO: hover needs to be done. not working
  initHover () {
    if (this.onValue === 'hover') {
      useHover(this)
    }
  }

  mouseEnter () {
    if (this.toggledWith) return
    this.#_open()
    this.toggledWith = 'hover'
  }

  mouseLeave () {
    if (this.toggledWith !== 'hover') return

    this.#_close()
  }

  open () {
    this.toggledWith = 'action'
    this.#_open()
  }

  close () {
    this.#_close()
  }

  #_open () {
    this.target.classList.add(this.classValue)
  }

  #_close () {
    this.target.classList.remove(this.classValue)
    this.toggledWith = null
  }
}
