const promises = {}
const states = ['loading', 'interactive', 'complete']

states.forEach((state, ownIndex) => {
  if (states.indexOf(document.readyState) >= ownIndex) {
    promises[state] = Promise.resolve()
  } else {
    promises[state] = new Promise(resolve => {
      document.addEventListener('readystatechange', ({ target: { readyState } }) => {
        states.indexOf(readyState) >= ownIndex && resolve()
      })
    })
  }
})

export const { loading, interactive, complete } = promises
