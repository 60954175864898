export default class Rect extends DOMRect {
  static properties =
    ['x', 'y', 'width', 'height', 'top', 'right', 'bottom', 'left', 'verticalCenter', 'horizontalCenter']

  constructor (element, rectRelativeTo = null) {
    let { x, y, width, height } = element.getBoundingClientRect()

    if (rectRelativeTo) {
      x = x - rectRelativeTo.x
      y = y - rectRelativeTo.y
    }
    super(x, y, width, height)
    this.element = element
  }

  get widthCenter () {
    return this.width / 2
  }

  get heightCenter () {
    return this.height / 2
  }

  get horizontalCenter () {
    return this.x + this.widthCenter
  }

  get verticalCenter () {
    return this.y + this.heightCenter
  }
}
