// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { createConsumer } from '@rails/actioncable'
import logger from 'lib/logger'
import { $ } from 'lib/query-selector'
import { interactive } from 'lib/async-ready-state'
import { getSession } from 'lib/session'

const { sessionStorage: storage } = globalThis

async function createConsumerAfterSession () {
  if (await ensureSessionRequired()) {
    await getSession()
    storage.setItem('sessionEnsuredAt', Date.now())
    logger.debug('session ensured')
    return createConsumer()
  } else {
    return createConsumer()
  }
}

async function ensureSessionRequired () {
  await interactive
  if (!$('meta[rel=page-caching][content=cached-page]')) return false

  const ensuredAt = parseInt(storage.getItem('sessionEnsuredAt'))
  return isNaN(ensuredAt) || parseInt(ensuredAt) < Date.now() - 86400 // not really needed because we use sessionStorage
}

export default createConsumerAfterSession()
