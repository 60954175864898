import ApplicationController from 'controllers/application-controller'
import debounce from 'lodash/debounce'

export default class RepaintingHeaderController extends ApplicationController {
  static values = {
    skipScrollOver: Number
  }

  connect () {
    this.elements = [this.element, document.body]
    this.skipScrollOver = this.hasSkipScrollOverValue ? this.skipScrollOverValue : 1024
    this.trackScroll = debounce(this.trackScroll.bind(this), 100)
    this.trackScroll()
  }

  trackScroll () {
    const { scrollY } = window
    if (scrollY > this.skipScrollOver && this.repainted) return
    const { height } = this.element.getBoundingClientRect()

    this.addWasScrolledClass()

    if (scrollY > height) {
      this.addRepainted()
    } else {
      this.removeRepainted()
    }
  }

  addWasScrolledClass () {
    if (this.wasScrolledClassAdded) return
    this.wasScrolledClassAdded = true
    document.documentElement.classList.add('was-scrolled')
  }

  addRepainted () {
    if (this.repainted) return

    this.elements.forEach(target => target.classList.add('header-repainted'))
    this.repainted = true
  }

  removeRepainted () {
    if (!this.repainted) return

    this.elements.forEach(target => target.classList.remove('header-repainted'))
    this.repainted = false
  }
}
