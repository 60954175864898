import ApplicationController from 'controllers/application-controller'
import { useIntersection } from 'stimulus-use'
import gtag from 'lib/gtag'

export default class IntentFormController extends ApplicationController {
  static values = {
    intentPresent: Boolean
  }

  static targets = ['body', 'valuableSpecifics', 'form', 'spinner', 'button']

  static elementsSelector = '.field, .fieldset, .button'

  connect () {
    super.connect()
    useIntersection(this)
    this.addEventListener('attachments:connect', e => this.#handleAttachmentsConnect(e))
    this.addEventListener('attachment:start', e => this.#handleAttachmentStart(e))
    this.addEventListener('attachment:complete', e => this.#handleAttachmentEnd(e))
  }

  appear () {
    this.track({ action: 'appear', label: 'Appear on the screen ' })
    this.appeared = true
    this.#ensureIntentPlease()
  }

  #handleAttachmentsConnect ({ detail: controller }) {
    this.attachmentsController = controller
  }

  #handleAttachmentStart () {
    this.buttonTargets.forEach(button => { button.setAttribute('disabled', 'disabled') })
  }

  #handleAttachmentEnd () {
    if (this.attachmentsController && !this.attachmentsController.allAttachmentsCompleted) return

    this.buttonTargets.forEach(button => { button.removeAttribute('disabled') })
  }

  #ensureIntentPlease () {
    if (this.intentPresent) return

    try {
      if (!this.isActionCableConnectionOpen || !this.isActionCableConnectionOpen()) throw new Error('not yet ready')
      this.displayWorking()
      this.#ensureIntent()
    } catch (e) { // connection not yet open, and subscription rejected errors
      console.log('Caught:', e, ', retrying in a 0.25s')
      setTimeout(() => this.#ensureIntentPlease(), 250) // retry
    }
  }

  #ensureIntent () {
    this.stimulate('IntentForm::Component#appear')
  }

  beforeHandleCategory () {
    this.track({ action: 'form_interaction', label: 'Visitor interacts with the form' })
    this.valuableSpecificsTarget.classList.add(
      'animate__animated',
      'animate__faster',
      'animate__slideOutUp'
    )
  }

  handleCategorySuccess () {
    this.valuableSpecificsTarget.classList.add(
      'animate__animated',
      'animate__faster',
      'animate__slideInDown'
    )
    this.valuableSpecificsTarget.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
  }

  beforeUpdate () {
    this.track({ action: 'form_interaction', label: 'Visitor interacts with the form' })
  }

  // afterUpdate (el) {
  //   const elements = Array.from(this.formTarget.querySelectorAll(this.constructor.elementsSelector))
  //   const current = el.closest(this.constructor.elementsSelector)
  //   const next = elements[elements.indexOf(current) + 1]
  //   if (next === elements[0]) return

  //   next?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
  // }

  nextStep (ev) {
    ev.preventDefault()
    this.track({ action: 'next_step', label: 'Visitor clicks PROCEED button' })
    this.displayWorking()
    this.scrollToSomething()
    this.#nextStepPlease()
  }

  #nextStepPlease () {
    if (!this.reflexReady) {
      globalThis.setTimeout(() => { this.#nextStepPlease() }, 100)
    } else {
      this.displayWorking()
      this.stimulate('IntentForm::Component#next_step', this.formTarget)
    }
  }

  previousStep (ev) {
    ev.preventDefault()
    this.track({ action: 'next_step', label: 'Visitor clicks BACK button' })
    this.displayWorking()
    this.scrollToSomething()
    this.#previousStepPlease()
  }

  #previousStepPlease () {
    if (!this.reflexReady) {
      globalThis.setTimeout(() => { this.#previousStepPlease() }, 100)
    } else {
      this.displayWorking()
      this.stimulate('IntentForm::Component#previous_step', this.formTarget)
    }
  }

  displayWorking () {
    this.bodyTarget.classList.add('working')
    this.spinnerTarget.style.position = this.formHigherThanScreen ? 'fixed' : 'absolute'
    this.spinnerTarget.style.display = 'block'
    this.buttonTargets.forEach(button => { button.setAttribute('disabled', 'disabled') })
  }

  hideWorking () {
    this.bodyTarget.classList.remove('working')
    this.spinnerTarget.style.display = 'none'
    this.buttonTargets.forEach(button => { button.removeAttribute('disabled') })
  }

  nextStepSuccess () {
    this.intentStatus === 'negotiation' && this.formCompleted()
    this.scrollToSomething()
  }

  formCompleted () {
    this.track({ action: 'complete', label: 'Form completed!' })
    gtag('event', 'conversion', { send_to: 'AW-405389345/jYxsCJvMk_4BEKGAp8EB' })
  }

  track ({ action, label }) {
    gtag('event', action, {
      event_category: 'Intent form',
      event_label: label
    })
  }

  scrollToSomething () {
    let element = this.element.querySelector('.field_with_errors')
    element = element || this.element.querySelector('.field')
    element = element || this.element

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    } else {
      console.warn('Nothing found to scroll to')
    }
  }

  get formHigherThanScreen () {
    return globalThis.visualViewport.height < this.element.getBoundingClientRect().height
  }

  get intentPresent () {
    // form not being rendered when intent is nil
    return this.hasFormTarget
  }

  get intentStatus () {
    return this.formTarget.dataset.intentStatus
  }
}
