import ApplicationController from 'controllers/application-controller'
import { DirectUpload } from '@rails/activestorage'

export default class AttachmentController extends ApplicationController {
  static values = {
    id: Number,
    url: String,
    attributeName: String,
    numericProgressType: String,
    validations: Object,
    hash: String
  }

  static targets = ['fileName', 'fileSize', 'progressBar', 'progressLine', 'numericProgress', 'preview', 'error', 'input']
  static stateClasses = ['pending', 'inProgress', 'failed', 'completed']
  static defaultClasses = { pending: 'pending', inProgress: 'in-progress', failed: 'failed', completed: 'completed' }
  static classes = this.stateClasses

  connect () {
    super.connect()
    this.#renderStateClass()
  }

  disconnect () {
    this.xhr && this.xhr.abort()
  }

  handleRemove (ev) {
    ev.preventDefault()
    ev.stopPropagation()
    this.dispatch('remove', this)
  }

  #validate () {
    if (!this.hasValidationsValue) return true

    return this.#validateContentType() && this.#validateSize()
  }

  get allowedContentTypes () {
    return (this.hasValidationsValue && this.validationsValue.content_type?.in) || null
  }

  get maxAllowedSize () {
    return (this.hasValidationsValue && this.validationsValue.size?.less_than) || null
  }

  #validateContentType () {
    if (!this.allowedContentTypes) return

    if (!this.allowedContentTypes.includes(this.file.type)) {
      this.#renderError('invalid format')
      return false
    }

    return true
  }

  #validateSize () {
    if (!this.maxAllowedSize) return

    if (this.file.size >= this.maxAllowedSize) {
      const current = (this.file.size / 1024 / 1024).toFixed(0)
      const allowed = (this.maxAllowedSize / 1024 / 1024).toFixed(0)
      this.#renderError(`${current} of ${allowed} mb allowed`)
      return false
    }

    return true
  }

  uploadFile (file) {
    this.file = file

    if (!this.#validate()) return

    this.dispatch('start', this)

    this.directUpload = new DirectUpload(file, this.urlValue, this)
    this.directUpload.create((error, attributes) => {
      if (error) {
        this.#handleUploadError(error)
      } else {
        this.hiddenInput = this.#createHiddenInput()
        this.hiddenInput.value = attributes.signed_id
        this.#handleUploadSuccess()
      }
    })
  }

  #renderStateClass () {
    this.constructor.stateClasses.forEach(cssClass => this.element.classList.remove(this.data.get(`${cssClass}-class`)))

    if (this.uploaded) {
      this.element.classList.add(this.completedClass)
    } else if (this.error) {
      this.element.classList.add(this.failedClass)
    } else if (this.inProgress) {
      this.element.classList.add(this.inProgressClass)
    } else {
      this.element.classList.add(this.pendingClass)
    }
  }

  #renderFile (file) {
    this.#renderFileName(file)
    this.#renderFilePreview(file)
    this.#renderFileSize(file)
  }

  #renderFileName (file) {
    if (!this.hasFileNameTarget) return

    this.fileNameTarget.innerHTML = file.name
  }

  #renderFilePreview (file) {
    if (!this.hasPreviewTarget || !file.type.startsWith('image/')) return

    const reader = new FileReader()
    reader.onload = ({ target: { result } }) => {
      this.previewTarget.style.backgroundImage = `url("${result}")`
    }
    reader.readAsDataURL(file)
  }

  #renderFileSize (file) {
    if (!this.hasFileSizeTarget) return

    this.fileSizeTarget.innerHTML = `${(file.size / 1024 / 1024).toFixed(2)}MB`
  }

  get file () {
    return this._file
  }

  set file (file) {
    this.#renderFile(file)
    this._file = file
  }

  #createHiddenInput () {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = this.attributeNameValue
    input.multiple = 'multiple'
    input.dataset[`${this.identifier}Target`] = 'input'
    this.element.appendChild(input)
    return input
  }

  directUploadWillStoreFileWithXHR (xhr) {
    this.xhr = xhr
    this.#bindProgressEvent(xhr)
    this.#handleStartUpload()
  }

  #bindProgressEvent (xhr) {
    this.xhr = xhr
    this.addEventListener('progress', event => this.#handleUploadProgress(event), { target: this.xhr.upload })
  }

  #handleStartUpload () {
    this.#renderStateClass()
  }

  #handleUploadProgress (event) {
    this.#renderProgressLine(event)
    this.#renderNumericProgress(event)
  }

  #renderProgressLine ({ loaded, total }) {
    if (!this.hasProgressLineTarget) return

    const width = loaded / total * 100
    this.progressLineTarget.style.width = `${width}%`
  }

  #renderNumericProgress ({ loaded, total }) {
    if (!this.hasNumericProgressTarget) return

    if (this.numericProgressTypeValue === 'absolute') {
      this.#renderAbsoluteProgress({ loaded, total })
    } else {
      this.numericProgressTarget.innerHTML = `${(loaded / total * 100).toFixed(2)}%`
    }
  }

  #renderAbsoluteProgress ({ loaded, total }) {
    const loadedMB = loaded / 1024 / 1024
    const totalMB = total / 1024 / 1024
    this.numericProgressTarget.innerHTML = `${loadedMB}/${totalMB}`
  }

  #handleUploadError (_) {
    this.#renderError('Upload Failed')
    this.dispatch('failure', this)
    this.dispatch('complete', this)
  }

  #renderError (message) {
    this.error = message
    const target = this.hasErrorTarget ? this.errorTarget : this.element
    target.innerHTML = message
    this.#renderStateClass()
  }

  #handleUploadSuccess () {
    this.#renderStateClass()
    this.dispatch('success', this)
    this.dispatch('complete', this)
  }

  get associated () {
    return this.hasIdValue
  }

  get uploaded () {
    return this.hasInputTarget
  }

  get completed () {
    return this.hasIdValue || this.uploaded || this.error || !this.#validate
  }

  get inProgress () {
    return this.xhr
  }

  get fileToUpload () {
    return this.element.fileToUpload
  }
}
