import { $ } from './pages-common'
const { setTimeout, sessionStorage: storage } = globalThis

globalThis.intercomSettings = {
  app_id: 'sj9ml69h'
}

function initIntercom () {
  if (storage.getItem('intercomInteraction') === 'true') {
    loadIntercom()
  } else {
    setTimeout(() => {
      loadIntercom()
      setTimeout(waitForIntercom, 200)
    }, 5000)
  }
}

if (process.env.RAILS_ENV === 'production') initIntercom()

function loadIntercom () {
  const w = window
  const ic = w.Intercom
  if (typeof ic === 'function') {
    ic('reattach_activator')
    ic('update', w.intercomSettings)
  } else {
    const d = document
    var i = function () { i.c(arguments) }
    i.q = []
    i.c = function (args) { i.q.push(args) }
    w.Intercom = i
    const l = function () {
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/sj9ml69h'
      const x = d.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    }
    if (document.readyState === 'loading') {
      if (w.attachEvent) { w.attachEvent('onload', l) } else { w.addEventListener('load', l, false) }
    } else {
      l()
    }
  }
}

let attemptsCount = 0

function waitForIntercom () {
  const button = $('.intercom-launcher')

  if (button) {
    button.addEventListener('click', () => { storage.setItem('intercomInteraction', 'true') }, { capture: true, once: true })
    return
  }

  attemptsCount += 1

  if (attemptsCount >= 100) {
    console.warn('Give up waiting for Intercom')
    storage.setItem('intercomInteraction', 'true')
    return
  }

  return setTimeout(waitForIntercom, 200)
}
