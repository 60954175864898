import ApplicationController from './application-controller'

export default class FlashMessageController extends ApplicationController {
  static values = {
    pageCached: Boolean,
    src: String
  }

  connect () {
    this.duration = 3500
    this.showClassName = 'animate__slideInDown'
    this.hideClassName = 'animate__slideOutUp'
    super.connect()
  }

  next () {
    if (this.hidden) {
      return this.flashController?.removeMessage(this) || this.element.remove()
    }

    this.hideSlow()
  }

  dismiss () {
    this.hide()
  }

  show () {
    this.element.classList.add(
      'animate__animated',
      'animate__faster',
      this.showClassName
    )
  }

  hideSlow () {
    this.element.classList.remove(this.showClassName)
    setTimeout(this.hide.bind(this), this.duration)
  }

  hide () {
    this.element.classList.remove(this.showClassName)
    this.element.classList.add(this.hideClassName)
  }

  get hidden () {
    return this.element.classList.contains(this.hideClassName)
  }
}
