import SliderController from './slider-control-controller'
import CellSlider from './slider-control-controller/cell-slider'
import Rect from 'lib/rect'

export default class CellSliderControlController extends SliderController {
  static SliderClass = CellSlider

  static targets = ['cell']

  static classes = SliderController.classes + ['selectedCell']
  static defaultClasses = Object.assign(SliderController.defaultClasses, { selectedCell: 'selected' })

  handleCellClick ({ currentTarget: cell }) {
    this.storeDimensions()
    const rect = new Rect(cell, this.slider.rect)
    const handles = Object.values(this.handles)
    const handle = handles.sort((one, other) => {
      return (
        Math.abs(one.rect.horizontalCenter - rect.horizontalCenter) -
          Math.abs(other.rect.horizontalCenter - rect.horizontalCenter)
      )
    })[0]
    handle.value = cell.dataset.value
  }
}
