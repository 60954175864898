function loaded (document) {
  const { documentElement: html } = document
  if (document.readyState === 'interactive') {
    html.classList.remove('no-js')
    html.classList.add('js')
  }
  setTimeout(() => { html.classList.add('delayed') }, 3000)
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', loaded)
} else {
  loaded(document)
}
