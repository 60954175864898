import Rect from 'lib/rect'

export default class Handle {
  static controllerAttrs = ['slider', 'hasHandleDraggingClass', 'handleDraggingClass', 'isPointAllowedFor']

  fillsToCareAbout = []
  /**
   * @param {Element} element
   * @param {HTMLInputElement} input
   * @param {SliderController} controller
   */
  constructor ({ element, input, controller }) {
    this.element = element
    this.input = input
    this.controller = controller
    Handle.controllerAttrs.forEach(key => {
      this[key] = controller[key]
    })

    this.initialize()
    document.addEventListener('stimulus-reflex:after', () => { this.initialize() })
  }

  initialize () {
    this.updateDimensions()
    this.value = this.input.value
    this.element.style.opacity = 1
  }

  updateDimensions () {
    this.rect = new Rect(this.element, this.controller.rect)
  }

  processDragStart () {
    this.hasHandleDraggingClass && this.element.classList.add(this.handleDraggingClass)
  }

  processDragPoint (point) {
    const newPoint = this.slider.clientPointToRelative(point)
    this.point = newPoint
    this.input.value = this.value
  }

  processDragEnd () {
    this.hasHandleDraggingClass && this.element.classList.remove(this.handleDraggingClass)
    this.slider.processDragEnd(this)
    // this.input.dispatchEvent(new Event('change', { bubbles: true }))
  }

  get point () {
    return this._point
  }

  set point (point) {
    if (!this.isPointAllowedFor({ point: point, handle: this })) return
    this._point = point
    this.fillsToCareAbout.forEach(fill => fill.render())
    this.element.style.left = `${point - this.rect.widthCenter}px`
    this.updateDimensions()
  }

  get value () {
    return this.slider.valueAtHandle(this)
  }

  set value (value) {
    this.input.value = value
    this.point = this.slider.pointAtValue(value, this)
  }

  get key () {
    if (this._key !== undefined) return this._key

    this._key = parseInt(this.element.dataset.key)
    return this._key
  }

  set key (key) {
    this.element.dataset.key = key
  }

  get currentCell () {
    return this._currentCell
  }

  set currentCell (cell) {
    if (cell === this._currentCell) return

    this._currentCell?.classList?.remove('selected')
    cell.classList.add('selected')
    this._currentCell = cell
  }
}
