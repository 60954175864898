import { Application, Controller } from 'stimulus'
import consumer from '../channels/consumer'
import StimulusReflex from 'stimulus_reflex'
import { $$ } from './query-selector'
import logger from './logger'
// maybe stimulus reflex initialization should separated to the another file

document.addEventListener('stimulus-reflex:ready', (ev) => {
  setTimeout(() => {
    const elements = $$('[data-reflex][data-action][disabled]')
    const count = elements.length
    if (count === 0) return

    elements.forEach(el => el.removeAttribute('disabled'))
    logger.debug(`${count} inputs enabled`)
  }, 100)
})

class DefaultStimulusReflexController extends Controller {
  constructor (...args) {
    super(...args)
    StimulusReflex.register(this)
  }
}

async function initializeConsumer () {
  window.application.consumer = await consumer

  logger.debug('consumer setted up')
}

if (!window.application) {
  window.application = Application.start()
  StimulusReflex.initialize(
    window.application, { controller: DefaultStimulusReflexController, debug: process.env.RAILS_ENV !== 'production' }
  )
  initializeConsumer()
}

export { Application, StimulusReflex, Controller }
export default window.application
