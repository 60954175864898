import { application } from 'lib/pages-common'
import IntentFormController from './intent-form-controller'
import ClassToggleController from 'controllers/class-toggle-controller'
import CellSliderController from 'controllers/cell-slider-control-controller'
import AttachmentsController from './attachments_component/attachments-controller'
import AttachmentController from './attachments_component/attachment-controller'

application.register('toggler', ClassToggleController)
application.register('cell-slider', CellSliderController)
application.register('attachments', AttachmentsController)
application.register('attachment', AttachmentController)
application.register('intent-form', IntentFormController)
