import ApplicationController from './application-controller'
import { getSession } from 'lib/session'

export default class FlashController extends ApplicationController {
  static values = {
    pageCached: Boolean,
    src: String
  }

  connect () {
    super.connect()
    this.delay = 1000
    this.lastShown = Date.now() - this.delay
    this.showQueue = []

    if (this.fetchRequired && this.fetchPossible) {
      this.fetchFlash()
    }
  }

  registerMessage (ev) {
    const messageController = ev.detail
    messageController.flashController = this
    this.showQueue.push(messageController)
    this.showMessages()
  }

  showMessages () {
    const diff = Date.now() - this.lastShown
    if (diff > this.delay) {
      const messageController = this.showQueue.shift()
      if (!messageController) return
      messageController.show()
      this.lastShown = Date.now()
      this.showMessages()
    } else {
      setTimeout(() => {
        this.showMessages()
      }, this.delay - diff + 1)
    }
  }

  removeMessage (messageController) {
    messageController.element.remove()
  }

  async fetchFlash () {
    await getSession()
    const response = await fetch(this.srcValue)
    this.element.outerHTML = await response.text()
  }

  get fetchRequired () {
    return this.hasPageCachedValue && this.pageCachedValue
  }

  get fetchPossible () {
    return this.hasSrcValue && this.srcValue
  }
}
