// import 'core-js'
// import 'regenerator-runtime/runtime'

import './bugsnag'
import './lozad-observer'
import './layout'
import './intercom'
import './logger'
import './body-js-class'
// import './smooth-scroll'
import './ensure-csrf-meta'
export { default as ahoy } from './ahoy'
import application from './stimulus-instance'
export { application }
export const $ = document.querySelector.bind(document)
export const $$ = document.querySelectorAll.bind(document)
export default application
