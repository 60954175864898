import lozad from 'lozad'

export class LozadExtentsion {
  static loadedListeners = [el => el.classList.add('lazy-loaded')]

  static get lozadObserver () {
    if (this._lozadObserver !== undefined) return this._lozadObserver

    this._lozadObserver = lozad('.lazy', {
      loaded: (el) => this.loadedListeners.forEach(listener => listener(el))
    })

    this._lozadObserver.observe()

    return this._lozadObserver
  }
}

export default LozadExtentsion.lozadObserver
