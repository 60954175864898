import { $ } from 'lib/query-selector'
import { interactive } from 'lib/async-ready-state'
import { getSession } from 'lib/session'

// TODO: move this out of here
function setCsrfMeta ({ csrfParam, csrfToken }) {
  document.head.insertAdjacentHTML(
    'beforeend',
    `<meta name="csrf-param" content="${csrfParam}" />
    <meta name="csrf-token" content="${csrfToken}" />`
  )
}

async function ensureCsrfMeta () {
  await interactive
  const csrfParamTag = $('meta[name=csrf-param]')
  const csrfTokenTag = $('meta[name=csrf-token]')

  if (csrfParamTag && csrfTokenTag) return
  const session = await getSession()
  setCsrfMeta(session)
}

ensureCsrfMeta()
