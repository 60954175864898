import ApplicationController from './application-controller'
import Slider from './slider-control-controller/slider'
import Handle from './slider-control-controller/handle'
import Fill from './slider-control-controller/fill'
import Rect from '../lib/rect'

export default class SliderControlController extends ApplicationController {
  static SliderClass = Slider

  static values = {
    min: Number,
    max: Number,
    valueMapFunction: String
  }

  static targets = ['slider', 'fill', 'input', 'handle']
  static classes = ['handleDragging']
  static defaultClasses = { handleDragging: 'is-dragging' }

  handles = {}
  handlesIndex = []
  fills = []

  connect () {
    super.connect()
    this.isPointAllowedFor = this.isPointAllowedFor.bind(this)
    this.#validateTargets()
    this.slider = new this.constructor.SliderClass({ element: this.sliderTarget, controller: this })
    this.#initializeHandles()
    this.#initializeFills()
    this.getReady = this.getReady.bind(this)
    this.reflexReady && this.getReady()
    addEventListener('stimulus-reflex:after', this.getReady)
  }

  disconnect () {
    removeEventListener('stimulus-reflex:after', this.getReady)
  }

  reflexBecomeReady () { this.getReady() }

  getReady () {
    this.storeDimensions()
    this.element.classList.add('ready')
  }

  #validateTargets () {
    if (this.inputTargets.length !== this.handleTargets.length) {
      console.error('SliderController: Count of inputs and the handles should be the same')
    }
  }

  #initializeHandles () {
    this.handleTargets.forEach((element, index) => {
      const handle = new Handle({
        element, input: this.inputTargets[index], controller: this
      })
      this.handles[handle.key] = handle
      const prev = this.handles[handle.key - 1]
      const next = this.handles[handle.key + 1]
      handle.position = prev === undefined ? 'first' : next === undefined ? 'last' : 'middle'
      handle.initialize()
      this.handlesIndex.push(handle.key)
    })
  }

  #initializeFills () {
    this.fillTargets.forEach((element, index) => {
      this.fills.push(new Fill({ element, controller: this }))
    })
  }

  isPointAllowedFor ({ handle, point }) {
    if (handle.point === point) return true
    const direction = point > handle.point ? 1 : -1
    const neighbourKey = this.handlesIndex[this.handlesIndex.indexOf(handle.key) + direction]
    if (!neighbourKey) return true
    const neighbour = this.handles[neighbourKey]
    return direction > 0 ? neighbour.point > point : neighbour.point < point
  }

  processInput ({ currentTarget: input }) {
    const handle = this.handles[input.dataset.handleKey]

    if (!handle) {
      console.warn('SliderController: handle not found')
      return
    }

    const value = this.slider.validateInput(handle)
    handle.value = value
  }

  startDrag ({ currentTarget: element }) {
    const handle = this.handles[element.dataset.key]

    if (!handle) {
      console.warn('SliderController: handle not found')
      return
    }

    this.currentHandle = handle
    this.storeDimensions()
    handle.processDragStart()
  }

  storeDimensions () {
    this.rect = new Rect(this.element)
    this.slider.updateDimensions()
    this.#updateHandlesRects()
  }

  #updateHandlesRects () {
    Object.values(this.handles).forEach(handle => handle.updateDimensions())
  }

  touchDrag ({ changedTouches }) {
    if (!changedTouches[0]) return
    const touch = changedTouches[0]
    if (!this.currentHandle || this.lastProcessedPoint === touch.clientX) return

    this.currentHandle.processDragPoint(touch.clientX)
    this.lastProcessedPoint = touch.clientX
  }

  mouseDrag (event) {
    if (!this.currentHandle || this.lastProcessedPoint === event.clientX) return

    this.currentHandle.processDragPoint(event.clientX)
    this.lastProcessedPoint = event.clientX
  }

  endDrag () {
    if (!this.currentHandle) return

    this.currentHandle.processDragEnd()
    this.currentHandle = null
    this.lastProcessedPoint = null
  }

  get leftValue () {
    return this.leftInputTarget.value
  }

  get rightValue () {
    return this.rightInputTarget.value
  }

  get valueMapFunctionName () {
    return this.hasValueMapFunctionValue ? this.valueMapFunctionValue : 'linear'
  }
}
