import { interactive } from 'lib/async-ready-state'
import { $ } from 'lib/query-selector'

let requestPromise = null
let session = null

const fetchSession = async () => {
  if (requestPromise) return requestPromise

  let _resolve

  requestPromise = new Promise((resolve) => {
    _resolve = resolve
  })

  try {
    const result = await fetch(new Request('/sessions.json'))
    if (result.status === 200) {
      const json = await result.json()
      _resolve(json)
    } else {
      throw result
    }
  } catch (err) {
    requestPromise = null
    throw err
  }

  return requestPromise
}

export async function getSession () {
  if (session) {
    return session
  } else {
    try {
      session = await fetchSession()
      return session
    } catch (err) {
      await new Promise(resolve => setTimeout(resolve, 500))
      return await getSession()
    }
  }
}

function metaValue (name) {
  const tag = $(`meta[name=${name}]`)
  if (!tag) return null

  const attribute = tag.getAttribute('content')
  if (!attribute || attribute.length == 0) return null

  return attribute
}

export const getCsrfParam = async () => {
  await interactive
  const fromMeta = metaValue('csrf-param')
  if (fromMeta) return fromMeta

  return (await getSession()).csrfParam
}

export const getCsrfToken = async () => {
  await interactive
  const fromMeta = metaValue('csrf-token')
  if (fromMeta) return fromMeta

  return (await getSession()).csrfToken
}
