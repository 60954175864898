import application from './stimulus-instance'
import RepaintingHeaderController from 'controllers/repainting-header-controller'
import NavigationController from 'controllers/navigation-controller'
import FlashController from 'controllers/flash-controller'
import FlashMessageController from 'controllers/flash-message-controller'
import CookiesAcceptanceComponentController from 'layout/cookies_acceptance_component/cookies-acceptance-component-controller'

application.register('repainting-header', RepaintingHeaderController)
application.register('navigation', NavigationController)
application.register('flash', FlashController)
application.register('flash-message', FlashMessageController)
application.register('cookies-acceptance-component', CookiesAcceptanceComponentController)
