import ApplicationController from 'controllers/application-controller'
import { useClickOutside } from 'stimulus-use'

export default class NavigationController extends ApplicationController {
  static targets = ['menu']

  connect () {
    this.menuTarget.style.display = 'block'
    useClickOutside(this, { element: this.menuTarget })
  }

  clickOutside (ev) {
    this.#close(ev)
  }

  handleOpenClick (ev) {
    if (this.open) return

    ev.preventDefault()
    this.menuTarget.classList.add('is-open')
    setTimeout(() => { this.open = true }, 200)
  }

  handleCloseClick (ev) {
    this.#close(ev)
  }

  #close (ev) {
    if (!this.open) return

    ev.preventDefault()
    this.menuTarget.classList.remove('is-open')
    this.open = false
  }
}
