import { Controller, StimulusReflex } from 'lib/stimulus-instance'
import { useApplication } from 'stimulus-use'
import { $ } from 'lib/query-selector'

export default class ApplicationController extends Controller {
  connect () {
    if (this.initialized) return
    this.initialized = true
    super.connect()
    this.#setDefaults()
    useApplication(this)
    this.eventListeners = []
    this.dispatch('connect', this)
    StimulusReflex.register(this)
    this.pretendToBeReflexReady()
  }

  disconnect () {
    super.disconnect()
    this.eventListeners.forEach(definition => {
      definition.target.removeEventListener(definition.type, definition.listener)
    })
    this.eventListeners = []
  }

  #setDefaults () {
    this.#setDefaultClasses()
  }

  // default css classes
  #setDefaultClasses () {
    const { defaultClasses } = this.constructor

    if (undefined === defaultClasses) return

    Object.keys(defaultClasses).forEach(name => {
      const fullName = `${name}Class`
      if (this.data.has(fullName)) return
      this.data.set(fullName, defaultClasses[name])
    })
  }

  setValues (values) {
    Object.keys(this.constructor.values).forEach(key => {
      const name = `${key}Value`
      if (undefined !== values[key]) this[name] = values[key]
    })
  }

  getNestedControllers (name) {
    const nestedControllers = []

    this.element.querySelectorAll(`[data-controller~="${name}"]`).forEach(element => {
      const controller = this.application.getControllerForElementAndIdentifier(element, name)
      if (controller) {
        nestedControllers.push(controller)
      }
    })

    return nestedControllers
  }

  getControllersByName (name) {
    const controllers = []

    document.querySelectorAll(`[data-controller~="${name}"]`).forEach(element => {
      const controller = this.application.getControllerForElementAndIdentifier(element, name)
      if (controller) {
        controllers.push(controller)
      }
    })

    return controllers
  }

  get csrfToken () {
    return $('meta[name=csrf-token]')?.content
  }

  addEventListener (type, listener, options = {}) {
    const target = options.target || this.element
    delete options.target
    target.addEventListener(type, listener, options)
    this.eventListeners.push({ type, listener, target })
  }

  reflexError (element, reflex, error, reflexId) {
    this.element.innerHTML =
    '<div class="reflex-error">' +
      '<div class="message">Something went wrong!</div>' +
      '<div class="button-place"><a href="" class="button">Reload</a></div>' +
    '</div>'
    this.scrollIntoView()
  }

  beforeReflex (_element) {
    this.reflexReady = false

    this.element.classList.remove('reflex-ready')
    this.element.classList.add('reflex-not-ready')
  }

  reflexHalted () {
    this.pretendToBeReflexReady()
  }

  afterReflex () {
    this.pretendToBeReflexReady()
  }

  pretendToBeReflexReady () {
    this.reflexReady = true
    this.element.classList.remove('reflex-not-ready')
    this.element.classList.add('reflex-ready')
  }

  scrollIntoView ({ element, options = { behavior: 'smooth', block: 'center', inline: 'center' } } = {}) {
    (element || this.element).scrollIntoView(options)
  }
}
