import Rect from 'lib/rect'

export default class CellSlider {
  constructor ({ element, controller }) {
    this.element = element
    this.controller = controller
    this.cells = controller.cellTargets
    this.updateDimensions()
  }

  updateDimensions () {
    this.rect = new Rect(this.element)
    this.cellsRects = []
    this.cells.forEach((cell) => {
      this.cellsRects.push(new Rect(cell, this.rect))
    })
  }

  processDragEnd (handle) {
    const value = this.valueAtHandle(handle)
    handle.point = this.pointAtValue(value, handle)
  }

  clientPointToRelative (point) {
    const { rect } = this
    const offsetPoint = point - rect.x

    if (offsetPoint < 0) {
      return 0
    } else if (offsetPoint > rect.width) {
      return rect.width
    }

    return offsetPoint
  }

  validateInput (handle) {
    const value = Number.parseFloat(handle.input.value)

    if (Number.isNaN(value)) {
      return handle.value
    }

    return value
  }

  valueAtHandle (handle) {
    const { point } = handle
    const rect = this.cellsRects.find(rect => rect.x <= point && rect.x + rect.width >= point)
    const cell = rect?.element
    if (cell) handle.currentCell = cell
    return cell?.dataset?.value
  }

  pointAtValue (value, handle = null) {
    const rect = this.cellsRects.find(rect => rect.element.dataset.value === value)
    const cell = rect?.element
    if (handle && cell) handle.currentCell = cell
    return rect?.[this.positionMap[handle?.position || 'middle']]
  }

  get map () {
    return this.constructor.maps[this.controller.valueMapFunctionName].normal
  }

  get reverseMap () {
    return this.constructor.maps[this.controller.valueMapFunctionName].reverse
  }

  get positionMap () {
    return {
      first: 'left',
      last: 'right',
      middle: 'horizontalCenter'
    }
  }
}
